<template>
  <div class="" id="frm_period">
    <v-dialog v-model="dialog" width="500">
      <v-card>
        <v-card-title class="headline grey lighten-2">
          กำหนดงวดใหม่
        </v-card-title>

        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12">
                <v-text-field
                  v-model="dataFrm.period_number"
                  required
                  type="number"
                  min="0"
                  max="1000000"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="success" @click="saveData">
            ตั้งงวดใหม่
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  name: "frm_period",
  data() {
    return {
      dataFrm: {
        period_number: 0,
      },
    };
  },
  props: {
    dialog: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    saveData(){
      this.dialog = false;
    },
  }
};
</script>

<style>
</style>