<template>
  <div id="period">
    <v-container grid-list-xs>
      <v-card>
        <v-card-title>
          ข้อมูลการกำหนดงวด
          <v-divider class="mx-4" inset vertical></v-divider>
          <Frm_period
            :dialog="frm_dialog"
            @callback="callback_frm_period"
          ></Frm_period>
          <v-spacer></v-spacer>
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
          ></v-text-field>
        </v-card-title>
        <v-data-table
          :headers="headers"
          :items="$store.getters.getlistPeriodAll"
          class="elevation-1"
          pagination.sync="pagination"
          item-key="id"
          :search="search"
        >
          <template v-slot:[`item.period_datestart1`]="{ item }">
            {{ converDateTime(item.period_datestart) }}
          </template>
          <template v-slot:[`item.period_dateend1`]="{ item }">
            {{ converDateTime(item.period_dateend) }}
          </template>
          <template v-slot:[`item.sum_money_of_period1`]="{ item }">
            <div class="text-right">
              {{ converNumtoCur(item.sum_money_of_period) }}
            </div>
          </template>
          <template v-slot:[`item.money_for_mem_lose`]="{ item }">
            <v-text-field
              class="pt-5"
              success
              prefix="฿"
              suffix="บาท"
              type="number"
              placeholder="ยอดเงินนำจ่าย"
              v-model="item.money_for_mem_lose"
              @keypress.enter="updateMoneyPeriod(item)"
              :disabled="item.period_status === 'Close'"
              outlined
              dense
            ></v-text-field>
          </template>
          <template v-slot:[`item.money_for_mem_other`]="{ item }">
            <v-text-field
              class="pt-5"
              success
              prefix="฿"
              suffix="บาท"
              type="number"
              placeholder="ยอดบบริการรายปี"
              v-model="item.money_for_mem_other"
              @keypress.enter="updateMoneyPeriod(item)"
              outlined
              dense
              ></v-text-field>
              <!-- :disabled="item.period_status === 'Close'" -->
          </template>
        </v-data-table>
      </v-card>
    </v-container>
  </div>
</template>

<script>
import Frm_period from "../components/frm_period";
var moment = require("moment");
export default {
  components: { Frm_period },
  name: "period",
  data() {
    return {
      loop: 0,
      search: "",
      headers: [
        { text: "งวดที่", value: "period_number" },
        { text: "วันที่เริ่ม", value: "period_datestart1" },
        { text: "วันที่สิ้นสุด", value: "period_dateend1" },
        { text: "สถานะ", value: "period_status" },
        { text: "ยอดเงินรับรวม", value: "sum_money_of_period1" },
        { text: "ยอดเงินนำจ่าย", value: "money_for_mem_lose" },
        { text: "ยอดเงินเพิ่มเติม", value: "money_for_mem_other" },
      ],
      frm_dialog: false,
      items: [],
    };
  },
  methods: {
    callback_frm_period() {
      this.frm_dialog = false;
    },
    converDateTime(date) {
      let mdt = moment(date).format("DD/MM/YYYY HH:mm:ss");
      return mdt;
    },
    converNumtoCur(num) {
      return Intl.NumberFormat("th-TH", {
        style: "currency",
        currency: "THB",
      }).format(num);
    },
    updateMoneyPeriod(item) {
      this.$store.dispatch('setMoneyPeriod', item);
    },
    async testAddData() {
      this.loop = 0.0;
      // let maxData = 50000;

      // for (let i = 0; i < maxData; i++) {
      await window.$.post(
        this.$store.state.pathAPI + "DB/testDATA",
        // { i: i },
        async (param) => {
          // this.loop = (i * 100) / maxData;
          await window.Swal.fire(param);
          await console.log(param);
        },
        "json"
      );
      // }
    },
    mounted() {
      this.$store.dispatch("getlistPeriodAll");
    },
  },
};
</script>

<style></style>
